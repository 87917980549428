<template>
  <div class="directory">
    <v-row class="ma-0 pa-0">
      <v-col
        class="ma-0 pa-0 leftContent"
        tabindex="0"
        cols="12"
        md="6"
        sm="12"
        xs="12"
      >
        <div class="title x-title" style="padding-top: 10px">
          <searchBox
            @search="search"
            @keypress.enter.native.prevent
          ></searchBox>
        </div>
        <div class="content-container">
          <div v-show="!showStaffList" class="content">
            <!--<i class="iconfont icon-ren" style="font-size: 3rem;color:#2D7D74;text-align: center"></i>-->
            <div tabindex="0">
              <p style="height: 0; opacity: 0">Directory image</p>
              <img
                src="@/assets/images/map/people.png"
                style="width: 10%; margin: 40px 12px"
                alt=""
              />
            </div>
            <p
              tabindex="0"
              style="font-size: 2rem; font-weight: bold; margin-bottom: 30px"
            >
              Directory
            </p>
            <v-row>
              <v-col cols="12" md="4" sm="4"></v-col>
              <v-col cols="12" md="5" sm="4" class="pa-0">
                <div class="notes">
                  <p>
                    <span class="note" tabindex="0"
                      >View colleague's phone number</span
                    >
                  </p>
                  <p>
                    <span class="note" tabindex="0">View profile photos</span>
                  </p>
                  <p>
                    <span class="note" tabindex="0"
                      >View job title and bio</span
                    >
                  </p>
                  <p>
                    <span class="note" tabindex="0"
                      >View organizational structure</span
                    >
                  </p>
                  <p>
                    <span class="note" tabindex="0">View campus location</span>
                  </p>
                </div>
              </v-col>
              <v-col cols="12" md="3" sm="4"></v-col>
            </v-row>
          </div>
          <div v-show="showStaffList" class="content staffList">
            <p
              class="totalNumber"
              tabindex="0"
              :aria-label="'result' + totalNumber"
            >
              Result: {{ totalNumber }}
            </p>
            <!--<p class="totalNumber" v-show="showTotal" tabindex="1">Result: {{ totalNumber }}</p>-->
            <staff
              v-for="(item, index) in attendees"
              :data="item"
              @showStaffDetail="showStaffDetail"
              @keyup.enter.native="showStaffDetail(item)"
              style="cursor: pointer"
              tabindex="0"
            ></staff>
          </div>
        </div>
      </v-col>
      <v-col
        class="ma-0 pa-0 rightContent"
        tabindex="0"
        v-if="showRight"
        cols="12"
        md="6"
        sm="12"
        xs="12"
      >
        <staffInfo
          ref="refStaffInfo"
          :data="staffDetail"
          @changeShowList="changeShowList"
          @showMore="showMore"
        ></staffInfo>
      </v-col>
      <v-col
        class="ma-0 pa-0 rightContent"
        v-if="!showRight"
        cols="12"
        md="6"
        sm="12"
        xs="12"
        style="height: calc(100vh - 76px); align-items: center; display: flex"
      >
        <div style="padding: 20%; text-align: center">
          Use the search to find colleagues and then click on them to view
          detail here.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import staff from "@/views/dashboard/componentsHa/staff";
import searchBox from "@/views/dashboard/componentsHa/searchBox";
import staffInfo from "@/views/dashboard/componentsHa/staffInfo";

export default {
  name: "directory",
  components: {
    searchBox,
    staff,
    staffInfo,
  },
  watch: {
    attendees(newVal, oldVal) {
      console.log("newVal:", newVal);
      this.totalNumber = newVal.length;
      // if (newVal.length > 0) {
      //   this.showTotal = true;
      // }
    },
  },
  data() {
    return {
      // showTotal: false,
      totalNumber: 0,
      cancelRequest: null,
      valid: false,
      name: "",
      nameRules: "",
      showStaffList: false, //还没进行搜索或搜索结果为空
      showEmpty: true,
      attendees: [], //人员名单
      staffTotal: 0, //查询出人员总数
      staffDetail: {}, //员工详细信息
      showRight: false, //右侧员工详细信息展示
      axiosNum: 0,
      cancel: {},
      showOrganazation: false, //显示员工组织架构
      timeOut: null,
      reportToIndex: 0,
      reportFromIndex: 0,
      orgStructureSize: 5,
      reportToTotal: 0,
      reportFromTotal: 0,
    };
  },

  created() {},

  mounted() {},

  methods: {
    // 后台返回的名字带分号(')有时候会变成乱码，前端转义一下（有点浪费性能，后续还是建议后端改）
    formatName(data) {
      console.log(data);
      data.forEach((item, index, arr) => {
        console.log("firstName", arr[index].firstName);
        if (item.name.includes("&apos;")) {
          console.log("zhaodaole", item);
          arr[index].name = item.name.replace(/&apos;/g, "'");
        }
        if (item.firstName.includes("&apos;")) {
          console.log("zhaodaole", item);
          arr[index].firstName = item.firstName.replace(/&apos;/g, "'");
        }
        if (item.lastName.includes("&apos;")) {
          console.log("zhaodaole", item);
          arr[index].lastName = item.lastName.replace(/&apos;/g, "'");
        }
      });
      console.log("data2:", data);
      return data;
    },
    // reportTo或reportFrom显示更多的人
    async showMore(type, id) {
      this.bus.$emit("loading", true);
      if (type == "to") {
        this.reportToIndex++;
        let orgStructureTo;
        await this.getOrgStructure("to", id).then((res) => {
          orgStructureTo = res;
        });
        this.$refs["refStaffInfo"].showReportFromList = false;
        this.staffDetail.orgStructure.reportTo =
          this.staffDetail.orgStructure.reportTo.concat(orgStructureTo);
        this.$refs["refStaffInfo"].showReportToList = false;
        if (
          this.staffDetail.orgStructure.reportTo.length >= this.reportToTotal
        ) {
          this.$refs["refStaffInfo"].showReportToList = true;
        }
        this.bus.$emit("loading", false);
      } else {
        this.reportFromIndex++;
        let orgStructureFrom;
        await this.getOrgStructure("from", id).then((res) => {
          orgStructureFrom = res;
        });
        // console.log("====orgStructureFrom =====",orgStructureFrom)
        this.$refs["refStaffInfo"].showReportFromList = false;
        this.staffDetail.orgStructure.reportFrom =
          this.staffDetail.orgStructure.reportFrom.concat(orgStructureFrom);
        this.$refs["refStaffInfo"].showReportFromList = true;
        // console.log("====reportFrom =====",this.staffDetail.orgStructure.reportFrom)
        // console.log("==== reportFromTotal=====",this.reportFromTotal)
        if (
          this.staffDetail.orgStructure.reportFrom.length >=
          this.reportFromTotal
        ) {
          this.$refs["refStaffInfo"].showMoreFrom = false;
        }
        this.bus.$emit("loading", false);
        // console.log("====this.staffDetail =====",this.staffDetail)
      }
    },
    // 右侧点击report to 或 reporting to的list
    changeShowList(data, showCloseBtn) {
      this.showStaffDetail(data, showCloseBtn, false); //第二个参数是用来控制右上角的关闭按钮显示与否
    },
    // 左侧搜索
    search(name) {
      if (name == "") {
        this.totalNumber = 0;
        // this.showTotal = false;
      }
      if (this.cancelRequest) {
        this.cancelRequest();
      }
      let CancelToken = axios.CancelToken;
      if (this.timeOut) {
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }
      if (name == "") {
        this.showStaffList = false;
        this.showRight = false;
        // this.cancelGetMsg();
        return;
      } else {
        this.timeOut = setTimeout(() => {
          let url = "";
          url = "/m/attendees/";
          axios({
            method: "GET",
            url: url, //利用了字符串模板来携带id
            data: {
              meetingId: localStorage.meetingIdMap
                ? localStorage.meetingIdMap
                : "",
              keyword: name,
              // token: window.localStorage.getItem('token')
            },
            cancelToken: new CancelToken((c) => {
              this.cancelRequest = c;
            }),
          })
            .then((res) => {
              // console.log('total:', res.data.total);
              let dateFormat = this.formatName(res.data.attendees);
              this.attendees = dateFormat;
              this.staffTotal = res.data.total;
              if ((res.data.total = 0)) {
                this.showStaffList = false;
              } else {
                this.showStaffList = true;
              }

              // this.attendees = res.data.attendees;
            })
            .catch((err) => {
              console.log(err);
            });
        }, 300);
      }
    },
    // 单独获取OrgStructure数据接口
    async getOrgStructure(type, attendeeId) {
      let data;
      await this.$axios({
        method: "GET",
        url: "/m/attendees/orgStructure",
        data: {
          type: type,
          page: type == "to" ? this.reportToIndex : this.reportFromIndex,
          size: this.orgStructureSize,
          attendeeId: attendeeId,
        },
      })
        .then((res) => {
          data = res.data.list;
          if (type == "to") {
            this.reportToTotal = res.data.total;
          } else {
            this.reportFromTotal = res.data.total;
          }
        })
        .catch((err) => {});
      return data;
    },

    // 显示右侧员工详细信息
    showStaffDetail(data, showCloseBtn = false, changeFirstIn = true) {
      this.showRight = false;
      console.log("data;", data);
      this.reportToIndex = 0;
      this.reportFromIndex = 0;
      this.reportToTotal = 0;
      this.reportFromTotal = 0;
      let that = this;
      this.bus.$emit("loading", true);
      this.$axios({
        method: "GET",
        url: "/m/attendees/",
        data: {
          meetingId: localStorage.meetingIdMap ? localStorage.meetingIdMap : "",
          attendeeId: data.id,
        },
      })
        .then(async (res) => {
          // data.locationShareStatus = res.data.locationShareStatus;
          this.staffDetail = res.data;
          // if (this.staffDetail.hasOwnProperty("orgStructure")) {
          //   this.bus.$emit("loading", false);
          // } else {
            let orgStructureTo;
            await this.getOrgStructure("to", data.id).then((res) => {
              orgStructureTo = res;
            });
            let orgStructureFrom;
            await this.getOrgStructure("from", data.id).then((res) => {
              orgStructureFrom = res;
            });
            let orgStructure = [];
            orgStructure.reportFrom = orgStructureFrom;
            orgStructure.reportTo = orgStructureTo;
            this.staffDetail.orgStructure = orgStructure;
            
            this.bus.$emit("loading", false);
          // }
          this.showRight = true;
          this.$nextTick(() => {
            console.log("====$refs =====", that.$refs.refStaffInfo);
            this.$refs.refStaffInfo.showCloseBtn = showCloseBtn;
            if (changeFirstIn) {
              this.$refs.refStaffInfo.firstInData = res.data;
            }
          });
        })
        .catch((err) => {
          this.bus.$emit("loading", false);
          console.log(err);
        });

      // this.getAttendee(data.id).then(res => {
      //   // console.log('res:', res);
      //   this.staffDetail = res.data;
      //   this.showRight = true;
      // }).catch(err => {
      //   console.log(err)
      // })
    },
    // showStaffDetail(data) {
    //   // console.log('data;',data);
    //   this.getAttendee(data.id).then(res => {
    //     // console.log('res:', res);
    //     this.staffDetail = res.data;
    //     this.showRight = true;
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
    // 获取员工详细信息
    getAttendee(id) {
      return axios({
        method: "GET",
        url: "/m/attendees/",
        data: {
          meetingId: localStorage.meetingIdMap ? localStorage.meetingIdMap : "",
          attendeeId: id,
          // token: window.localStorage.getItem('token')
        },
      });
    },
    // 中断axios请求(解决异步请求的问题)
    cancelGetMsg() {
      for (let key in this.cancel) {
        let fun = this.cancel[key];
        fun();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.directory {
  display: flex;
  flex-direction: column;
  height: 100%;

  .leftContent {
    .title {
      //background: $main-blue;
      color: #ffffff;
      height: 60px;
      line-height: 60px;
      text-align: center;
      padding: 0 20px;
    }

    .content-container {
      border: 1px solid #999999;

      .totalNumber {
        text-align: left;
        font-size: 18px;
        margin: 5px 10px;
      }

      .content {
        height: 650px;
        background: #fff;
        text-align: center;
        overflow-y: auto;
        overflow-x: hidden;

        .notes {
          text-align: left;

          .note:before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            //background: $btn-color;
            //background: #ee2c54;
            border-radius: 50%;
            margin-right: 1rem;
          }
        }
      }

      .staffList {
        .staff:nth-child(odd) {
          background: #f3f3f3;
        }

        .staff:nth-child(even) {
          background: #fff;
        }
      }
    }
  }

  .rightContent {
    height: 650px;
    background: #fff;

    .title {
      //background: $main-blue;
      color: #ffffff;
      height: 120px;
      text-align: center;
      padding: 0 20px;
      display: flex;

      .staff {
        display: flex;
        align-items: center;

        .right {
          text-align: left;
          font-size: 1rem;
          padding-left: 1rem;

          .name {
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
      }
    }

    .rightContent-content {
      height: 592px;
      overflow-y: auto;
      background: #fff;
      border: 1px solid #999;
      border-left: none;

      .staffContent {
        .staffDetails {
          padding-top: 20px;
          padding-left: 30px;

          .oneDetail {
            margin-bottom: 1rem;

            .oneDetail-title {
              display: inline-block;
              width: 15%;
              min-width: 130px;
            }
          }
        }

        .staffContent-btn {
          text-align: center;
          margin: 1.5rem auto;
        }
      }

      //上司
      .leadership {
        .x-title {
          background: #f3f3f3;
          height: 60px;
          line-height: 60px;
          color: #000;
          font-size: 1rem;
          font-weight: bold;
          padding-left: 30px;
        }

        .x-content {
          padding-left: 30px;
        }
      }

      //下属
      .subordinate {
        .x-title {
          background: #f3f3f3;
          height: 60px;
          line-height: 60px;
          color: #000;
          font-size: 1rem;
          font-weight: bold;
          padding-left: 30px;
        }

        .x-content {
          padding-left: 30px;
        }
      }

      //摘要
      .brief {
        background: #fff;
        padding-top: 2rem;
      }
    }
  }
}
</style>

