<template>
  <div class="x-search">
    <v-form v-model="valid">
      <v-text-field
        v-model="name"
        :rules="nameRules"
        placeholder="Search"
        required
        prepend-inner-icon="search"
        color="#4f97a6"
        solo
        autocomplete="off"
        @input="searchName"
        v-on:keyup.enter="enterName"
      ></v-text-field>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "searchBox",
  data() {
    return {
      valid:true,
      name:'',
      nameRules:[]
    }
  },
  methods: {
    searchName(){
      this.$emit("search",this.name)
    },
    enterName(){
      return false
    }
  }
}
</script>

<style lang="scss">
.x-search {
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
    border-radius: 50px;
    height:40px!important;
    box-shadow: unset!important;
    min-height: unset!important;
    //border:1px solid #999999;
  }
}
</style>
